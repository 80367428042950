@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

h1 {
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
  font-size: 35px;
}

h2 {
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 300;
    font-size: 24px;
}

h3 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
}

.form-group{
    margin-bottom: 0.5rem !important;
}

.center {
    text-align: center;
}

.signUpButton {
    margin: 1px;
}

.btn{
    margin: 10.5px;
    border-radius: 0rem;
    color: white;
    width: 85%;
    height: 3rem;
    text-align: center;
}
.CheckInCheckOut{
    text-align: center;
}

.MakeanAccount{
    text-align: center;
}

.buttonDiv {
    text-align: center;
}

.PhoneInputInput {
    @extend .form-control;
}

.dropdown-toggle {
    @extend .form-control;
}

.btn-light {
    @extend .form-control;
    background-color: #FFFFFF !important;
    margin: 0 !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
}

.btn-light:focus, .btn-light.focus {
    background-color: #FFFFFF;
}

//Progress Bar styles
$brand-primary: #2C5A74;
$brand-secondary: #2C5A74;
$white: #fff;
$grey-light: #e0e0e0;
%remain-steps{
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  &:after{
    background-color: $grey-light;
  }
}
.multi-steps{
  display: table;
  table-layout: fixed;
  width: 100%;
  > li{
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $brand-primary;
    
    &:before{
      content: '\f00c';
      content: '\2713;';
      content: '\10003';
      content: '\10004';
      content: '\2713';
      color: $white;
      display: block;
      margin: 0 auto 4px;
      background-color: $brand-primary;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border-width: 2px;
      border-style: solid;
      border-color: $brand-primary;
      border-radius: 50%;
    }
    &:after{
      content: '';
      height: 2px;
      width: calc(100% - 36px);
      background-color: $brand-primary;
      position: absolute;
      top: 16px;
      left: calc(50% + 18px);
    }
    &:last-child{
      &:after{
        display: none;
      }
    }

    &.is-active{
      @extend %remain-steps;
      color: $brand-secondary;
      &:before{
        color: $brand-primary;
        background-color: $white;
        border-color: $brand-secondary;
      }

      ~ li{
        color: #808080;
        @extend %remain-steps;
        &:before{
          background-color: $grey-light;
          border-color: $grey-light;
        }
      }
    }
  }
}
// Override default variables before the import
$body-bg: #FFFFFF;

$theme-colors: (
  primary: #2C5A74,
  secondary: #F4932D,
);

$btn-line-height-lg: 48px;
$btn-border-radius-lg: 0;
$btn-padding-y-lg: 0px;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
